<script src="js/flexible.js"></script>
<script src="js/jquery.js"></script>
<script src="js/echarts.min.js"></script>
<script src="js/index.js"></script>
<script src="js/china.js"></script>
<script src="js/myMap.js"></script>

<template>
  <body class="bodytest">
    <div style="width: 100%; padding: 0.3rem 0.3rem; background-color: rgb(213, 234, 247); color: rgb(87, 161, 240);text-align: center; font-size: 0.9rem;" >咨询或有任何疑问回到小程序咨询</div>

    <div style=" margin: 0.5rem 0.5rem;">

      <!-- 体质指数 -->
      <div v-if="index==1">
        <div style="margin: 0.5rem 0rem; margin-top: 1rem; font-weight: 600;">体质BMI</div>
        <div style="width: 100%; height: 5rem; background-color: rgb(255, 255, 255); border-radius: 0.5rem;">
          <div style="display: flex; text-align: center;">
            <div style="flex: 70;">
              <div style="margin: 0.5rem 0.5rem; margin-top: 0.7rem;">体重</div>
              <div style="margin: 0.5rem 0.5rem; font-weight: 600; font-size: 1.5rem;">{{ lastDataDetails.weight }}KG</div>
            </div>
            <div style="flex: 1; background-color: rgb(216, 216, 216); height: 3rem; margin-top: 1rem;"></div>
            <div style="flex: 70; justify-content: center;">
              <div style="margin: 0.5rem 0.5rem; margin-top: 0.7rem;">身高</div>
              <div style="margin: 0.5rem 0.5rem; font-weight: 600; font-size: 1.5rem;">{{ lastDataDetails.weight }}cm</div>
            </div>
            <div style="flex: 1; background-color: rgb(216, 216, 216); height: 3rem; margin-top: 1rem;"></div>
            <div style="flex: 70; justify-content: center;">
              <div style="margin: 0.5rem 0.5rem; margin-top: 0.7rem; color: rgb(79, 154, 206);">BMI</div>
              <div style="margin: 0.5rem 0.5rem; font-weight: 600; font-size: 1.5rem; color: rgb(79, 154, 206);">{{ lastDataDetails.weightBMI }}</div>
            </div>
          </div>
        </div>
        <div style="height: 0.5rem;"></div>
        <div style="width: 100%; height: 8rem; background-color: rgb(255, 255, 255); border-radius: 0.5rem; ">
          <div style="position: relative;">
            <div style="position: absolute; top: 1rem; left: 1rem;">BMI</div>
            <div v-if="lastDataDetails.weightColorshow == 1" style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">低</div>
            <div v-if="lastDataDetails.weightColorshow == 2" style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">正常</div>
            <div v-if="lastDataDetails.weightColorshow == 3" style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">高</div>
          </div>
          <div style="position: relative;">
            <div class="tooltip" :style="{'margin-left': lastDataShow.weightShow + '%'}">
            <!-- <div class="tooltip" style="margin-left: 70%"> -->
              {{ lastDataDetails.weightBMI }}
            </div>
          </div>
          <div style="display: flex; text-align: center; position: relative; top: 4rem; margin: 0.5rem 0.5rem;">
            <div style="flex: 70; background-color: rgb(216, 216, 216); margin: 0rem 0.1rem; border-top-left-radius: 1rem; border-bottom-left-radius: 1rem;" :style="{'background-color': lastDataShow.weightShowColor1}">
              <div style="color: rgb(255, 255, 255); margin: 0.2rem 0rem;">&lt;18.5</div>
            </div>
            <div style="flex: 70; background-color: rgb(57, 135, 247); margin: 0rem 0.1rem;" :style="{'background-color': lastDataShow.weightShowColor2}">
              <div style="color: rgb(255, 255, 255); margin: 0.2rem 0rem;">18.5 - 23.9</div>
            </div>
            <div style="flex: 70; background-color: rgb(216, 216, 216); margin: 0rem 0.1rem; border-top-right-radius: 1rem; border-bottom-right-radius: 1rem;" :style="{'background-color': lastDataShow.weightShowColor3}">
              <div style="color: rgb(255, 255, 255); margin: 0.2rem 0rem;">&gt;23.9</div>
            </div>
          </div>
          <div style="display: flex; text-align: center; position: relative; top: 4rem; margin: 0.5rem 0.5rem;">
            <div style="flex: 70;">
              <div>低</div>
            </div>
            <div style="flex: 70;">
              <div>正常</div>
            </div>
            <div style="flex: 70;">
              <div>高</div>
            </div>
          </div>
        </div>
        <div style="position: relative; margin-top: 1rem;">
          <div style="position: absolute; font-size: 1.1rem;">历史数据</div>
          <div style="position: absolute; margin-left: 5.5rem;">指标统计</div>
        </div>
      </div>

      <!-- 体温 -->
      <div v-if="index==2">
        <div style="margin: 0.5rem 0rem; margin-top: 1rem; font-weight: 600;">体温</div>
        <div style="width: 100%; height: 3rem; background-color: rgb(255, 255, 255); border-radius: 0.5rem;">
          <div style="display: flex; text-align: center;">
            <div style="flex: 70;">
              <div style="margin: 0.5rem 0.5rem; font-weight: 600; font-size: 1.5rem;">{{ lastDataDetails.btValue }}℃</div>
            </div>
          </div>
        </div>
        <div style="height: 0.5rem;"></div>
        <div style="width: 100%; height: 8rem; background-color: rgb(255, 255, 255); border-radius: 0.5rem; ">
          <div style="position: relative;">
            <div style="position: absolute; top: 1rem; left: 1rem;">体温</div>
            <!-- <div style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">正常</div> -->
            <div v-if="lastDataDetails.btColorshow == 1" style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">低</div>
            <div v-if="lastDataDetails.btColorshow == 2" style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">正常</div>
            <div v-if="lastDataDetails.btColorshow == 3" style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">高</div>
          </div>
          <div style="position: relative;">
            <div class="tooltip" :style="{'margin-left': lastDataShow.btShow + '%'}">
              {{ lastDataDetails.btValue }}℃
            </div>
          </div>
          <div style="display: flex; text-align: center; position: relative; top: 4rem; margin: 0.5rem 0.5rem;">
            <div style="flex: 70; background-color: rgb(216, 216, 216); margin: 0rem 0.1rem; border-top-left-radius: 1rem; border-bottom-left-radius: 1rem;" :style="{'background-color': lastDataShow.btShowColor1}">
              <div style="color: rgb(255, 255, 255); margin: 0.2rem 0rem;">&lt;36.0</div>
            </div>
            <div style="flex: 70; background-color: rgb(57, 135, 247); margin: 0rem 0.1rem;" :style="{'background-color': lastDataShow.btShowColor2}">
              <div style="color: rgb(255, 255, 255); margin: 0.2rem 0rem;">36.0 - 37.2</div>
            </div>
            <div style="flex: 70; background-color: rgb(216, 216, 216); margin: 0rem 0.1rem; border-top-right-radius: 1rem; border-bottom-right-radius: 1rem;" :style="{'background-color': lastDataShow.btShowColor3}">
              <div style="color: rgb(255, 255, 255); margin: 0.2rem 0rem;">&gt;37.2</div>
            </div>
          </div>
          <div style="display: flex; text-align: center; position: relative; top: 4rem; margin: 0.5rem 0.5rem;">
            <div style="flex: 70;">
              <div>低</div>
            </div>
            <div style="flex: 70;">
              <div>正常</div>
            </div>
            <div style="flex: 70;">
              <div>高</div>
            </div>
          </div>
        </div>
        <div style="position: relative; margin-top: 1rem;">
          <div style="position: absolute; font-size: 1.1rem;">历史数据</div>
          <div style="position: absolute; margin-left: 5.5rem;">指标统计</div>
        </div>
        <div style="position: relative; margin-top: 3rem; width: 100%; background-color: rgb(255, 255, 255); border-radius: 0.5rem; padding: 1rem 1rem;">
          <div style="display: flex; text-align: center;">
            <div style="flex: 1;justify-content: center;">
              时间
            </div>
            <div style="flex: 1; justify-content: center;">
              体温
            </div>
            <div style="flex: 1; justify-content: center;">
              操作
            </div>
          </div>
          <div style="height: 0.5rem;"></div>
          <!-- <div style="display: flex; text-align: center; margin: 1.2rem 0rem;">
            <div style="flex: 1;justify-content: center;">
              2021-01-05
            </div>
            <div style="flex: 1; justify-content: center;">
              35.9℃
            </div>
            <div style="flex: 1; justify-content: center;">
              查看详情
            </div>
          </div> -->
          <div v-for="item in historyList" :key="item.id" style="display: flex; text-align: center; margin: 1.2rem 0rem;">
            <div style="flex: 1;justify-content: center;">
              {{ item.btDate }}
            </div>
            <div style="flex: 1; justify-content: center;">
              {{ item.btValue }}℃
            </div>
            <div style="flex: 1; justify-content: center;">
              <!-- 查看详情 -->
            </div>
          </div>
        </div>
      </div>

      <!-- 心率 -->
      <div v-if="index==3">
        <div style="margin: 0.5rem 0rem; margin-top: 1rem; font-weight: 600;">心率</div>
        <div style="width: 100%; height: 3rem; background-color: rgb(255, 255, 255); border-radius: 0.5rem;">
          <div style="display: flex; text-align: center;">
            <div style="flex: 70;">
              <div style="margin: 0.5rem 0.5rem; font-weight: 600; font-size: 1.5rem;">{{ lastDataDetails.heartRate }}bpm</div>
            </div>
          </div>
        </div>
        <div style="height: 0.5rem;"></div>
        <div style="width: 100%; height: 8rem; background-color: rgb(255, 255, 255); border-radius: 0.5rem; ">
          <div style="position: relative;">
            <div style="position: absolute; top: 1rem; left: 1rem;">心率</div>
            <div v-if="lastDataDetails.heartRateColorshow == 1" style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">低</div>
            <div v-if="lastDataDetails.heartRateColorshow == 2" style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">正常</div>
            <div v-if="lastDataDetails.heartRateColorshow == 3" style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">高</div>
          </div>
          <div style="position: relative;">
            <div class="tooltip" :style="{'margin-left': lastDataShow.heartRateShow + '%'}">
              {{ lastDataDetails.heartRate }}
            </div>
          </div>
          <div style="display: flex; text-align: center; position: relative; top: 4rem; margin: 0.5rem 0.5rem;">
            <div style="flex: 70; background-color: rgb(216, 216, 216); margin: 0rem 0.1rem; border-top-left-radius: 1rem; border-bottom-left-radius: 1rem;" :style="{'background-color': lastDataShow.heartRateShowColor1}">
              <div style="color: rgb(255, 255, 255); margin: 0.2rem 0rem;">&lt;60</div>
            </div>
            <div style="flex: 70; background-color: rgb(57, 135, 247); margin: 0rem 0.1rem;" :style="{'background-color': lastDataShow.heartRateShowColor2}">
              <div style="color: rgb(255, 255, 255); margin: 0.2rem 0rem;">60 - 100</div>
            </div>
            <div style="flex: 70; background-color: rgb(216, 216, 216); margin: 0rem 0.1rem; border-top-right-radius: 1rem; border-bottom-right-radius: 1rem;" :style="{'background-color': lastDataShow.heartRateShowColor3}">
              <div style="color: rgb(255, 255, 255); margin: 0.2rem 0rem;">&gt;100</div>
            </div>
          </div>
          <div style="display: flex; text-align: center; position: relative; top: 4rem; margin: 0.5rem 0.5rem;">
            <div style="flex: 70;">
              <div>低</div>
            </div>
            <div style="flex: 70;">
              <div>正常</div>
            </div>
            <div style="flex: 70;">
              <div>高</div>
            </div>
          </div>
        </div>
        <div style="position: relative; margin-top: 1rem;">
          <div style="position: absolute; font-size: 1.1rem;">历史数据</div>
          <div style="position: absolute; margin-left: 5.5rem;">指标统计</div>
        </div>
        <div style="position: relative; margin-top: 3rem; width: 100%; background-color: rgb(255, 255, 255); border-radius: 0.5rem; padding: 1rem 1rem;">
          <div style="display: flex; text-align: center;">
            <div style="flex: 1;justify-content: center;">
              时间
            </div>
            <div style="flex: 1; justify-content: center;">
              心率
            </div>
            <div style="flex: 1; justify-content: center;">
              操作
            </div>
          </div>
          <div style="height: 0.5rem;"></div>
          <!-- <div style="display: flex; text-align: center; margin: 1.2rem 0rem;">
            <div style="flex: 1;justify-content: center;">
              2021-01-05
            </div>
            <div style="flex: 1; justify-content: center;">
              92bpm
            </div>
            <div style="flex: 1; justify-content: center;">
              查看详情
            </div>
          </div> -->
          <div v-for="item in historyList" :key="item.id" style="display: flex; text-align: center; margin: 1.2rem 0rem;">
            <div style="flex: 1;justify-content: center;">
              {{ item.meterDate }}
            </div>
            <div style="flex: 1; justify-content: center;">
              {{ item.heartRate }}bpm
            </div>
            <div style="flex: 1; justify-content: center;">
              <!-- 查看详情 -->
            </div>
          </div>
        </div>
      </div>

      <!-- 呼吸 -->
      <div v-if="index==4">
        <div style="margin: 0.5rem 0rem; margin-top: 1rem; font-weight: 600;">呼吸</div>
        <div style="width: 100%; height: 3rem; background-color: rgb(255, 255, 255); border-radius: 0.5rem;">
          <div style="display: flex; text-align: center;">
            <div style="flex: 70;">
              <div style="margin: 0.5rem 0.5rem; font-weight: 600; font-size: 1.5rem;">{{ lastDataDetails.breathValue }} 次/分钟</div>
            </div>
          </div>
        </div>
        <div style="height: 0.5rem;"></div>
        <div style="width: 100%; height: 8rem; background-color: rgb(255, 255, 255); border-radius: 0.5rem; ">
          <div style="position: relative;">
            <div style="position: absolute; top: 1rem; left: 1rem;">呼吸</div>
            <div v-if="lastDataDetails.breathValueColorshow == 1" style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">低</div>
            <div v-if="lastDataDetails.breathValueColorshow == 2" style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">正常</div>
            <div v-if="lastDataDetails.breathValueColorshow == 3" style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">高</div>
          </div>
          <div style="position: relative;">
            <div class="tooltip" :style="{'margin-left': lastDataShow.breathValueShow + '%'}">
              {{ lastDataDetails.breathValue }}
            </div>
          </div>
          <div style="display: flex; text-align: center; position: relative; top: 4rem; margin: 0.5rem 0.5rem;">
            <div style="flex: 70; background-color: rgb(216, 216, 216); margin: 0rem 0.1rem; border-top-left-radius: 1rem; border-bottom-left-radius: 1rem;" :style="{'background-color': lastDataShow.breathValueShowColor1}">
              <div style="color: rgb(255, 255, 255); margin: 0.2rem 0rem;">&lt;12</div>
            </div>
            <div style="flex: 70; background-color: rgb(57, 135, 247); margin: 0rem 0.1rem;" :style="{'background-color': lastDataShow.breathValueShowColor2}">
              <div style="color: rgb(255, 255, 255); margin: 0.2rem 0rem;">12 - 20</div>
            </div>
            <div style="flex: 70; background-color: rgb(216, 216, 216); margin: 0rem 0.1rem; border-top-right-radius: 1rem; border-bottom-right-radius: 1rem;" :style="{'background-color': lastDataShow.breathValueShowColor3}">
              <div style="color: rgb(255, 255, 255); margin: 0.2rem 0rem;">&gt;20</div>
            </div>
          </div>
          <div style="display: flex; text-align: center; position: relative; top: 4rem; margin: 0.5rem 0.5rem;">
            <div style="flex: 70;">
              <div>低</div>
            </div>
            <div style="flex: 70;">
              <div>正常</div>
            </div>
            <div style="flex: 70;">
              <div>高</div>
            </div>
          </div>
        </div>
        <div style="position: relative; margin-top: 1rem;">
          <div style="position: absolute; font-size: 1.1rem;">历史数据</div>
          <div style="position: absolute; margin-left: 5.5rem;">指标统计</div>
        </div>
        <div style="position: relative; margin-top: 3rem; width: 100%; background-color: rgb(255, 255, 255); border-radius: 0.5rem; padding: 1rem 1rem;">
          <div style="display: flex; text-align: center;">
            <div style="flex: 1;justify-content: center;">
              时间
            </div>
            <div style="flex: 1; justify-content: center;">
              呼吸
            </div>
            <div style="flex: 1; justify-content: center;">
              操作
            </div>
          </div>
          <div style="height: 0.5rem;"></div>
          <!-- <div style="display: flex; text-align: center; margin: 1.2rem 0rem;">
            <div style="flex: 1;justify-content: center;">
              2021-01-05
            </div>
            <div style="flex: 1; justify-content: center;">
              12 次/分钟
            </div>
            <div style="flex: 1; justify-content: center;">
              查看详情
            </div>
          </div> -->
          <div v-for="item in historyList" :key="item.id" style="display: flex; text-align: center; margin: 1.2rem 0rem;">
            <div style="flex: 1;justify-content: center;">
              {{ item.breathDate }}
            </div>
            <div style="flex: 1; justify-content: center;">
              {{ item.breathValue }}次/分钟
            </div>
            <div style="flex: 1; justify-content: center;">
              <!-- 查看详情 -->
            </div>
          </div>
        </div>
      </div>

      <!-- 血氧 -->
      <div v-if="index==5">
        <div style="margin: 0.5rem 0rem; margin-top: 1rem; font-weight: 600;">血氧</div>
        <div style="width: 100%; height: 3rem; background-color: rgb(255, 255, 255); border-radius: 0.5rem;">
          <div style="display: flex; text-align: center;">
            <div style="flex: 70;">
              <div style="margin: 0.5rem 0.5rem; font-weight: 600; font-size: 1.5rem;">{{ lastDataDetails.boValue }}%</div>
            </div>
          </div>
        </div>
        <div style="height: 0.5rem;"></div>
        <div style="width: 100%; height: 8rem; background-color: rgb(255, 255, 255); border-radius: 0.5rem; ">
          <div style="position: relative;">
            <div style="position: absolute; top: 1rem; left: 1rem;">血氧</div>
            <div v-if="lastDataDetails.boValueColorshow == 1" style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">低</div>
            <div v-if="lastDataDetails.boValueColorshow == 2" style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">正常</div>
            <div v-if="lastDataDetails.boValueColorshow == 3" style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">高</div>
          </div>
          <div style="position: relative;">
            <div class="tooltip" :style="{'margin-left': lastDataShow.boValueShow + '%'}">
              {{ lastDataDetails.boValue }}
            </div>
          </div>
          <div style="display: flex; text-align: center; position: relative; top: 4rem; margin: 0.5rem 0.5rem;">
            <div style="flex: 70; background-color: rgb(216, 216, 216); margin: 0rem 0.1rem; border-top-left-radius: 1rem; border-bottom-left-radius: 1rem;" :style="{'background-color': lastDataShow.boValueShowColor1}">
              <div style="color: rgb(255, 255, 255); margin: 0.2rem 0rem;">&lt;95</div>
            </div>
            <div style="flex: 70; background-color: rgb(57, 135, 247); margin: 0rem 0.1rem;" :style="{'background-color': lastDataShow.boValueShowColor2}">
              <div style="color: rgb(255, 255, 255); margin: 0.2rem 0rem;">95 - 100</div>
            </div>
            <div style="flex: 70; background-color: rgb(216, 216, 216); margin: 0rem 0.1rem; border-top-right-radius: 1rem; border-bottom-right-radius: 1rem;" :style="{'background-color': lastDataShow.boValueShowColor3}">
              <div style="color: rgb(255, 255, 255); margin: 0.2rem 0rem;">&gt;100</div>
            </div>
          </div>
          <div style="display: flex; text-align: center; position: relative; top: 4rem; margin: 0.5rem 0.5rem;">
            <div style="flex: 70;">
              <div>低</div>
            </div>
            <div style="flex: 70;">
              <div>正常</div>
            </div>
            <div style="flex: 70;">
              <div>高</div>
            </div>
          </div>
        </div>
        <div style="position: relative; margin-top: 1rem;">
          <div style="position: absolute; font-size: 1.1rem;">历史数据</div>
          <div style="position: absolute; margin-left: 5.5rem;">指标统计</div>
        </div>
        <div style="position: relative; margin-top: 3rem; width: 100%; background-color: rgb(255, 255, 255); border-radius: 0.5rem; padding: 1rem 1rem;">
          <div style="display: flex; text-align: center;">
            <div style="flex: 1;justify-content: center;">
              时间
            </div>
            <div style="flex: 1; justify-content: center;">
              血氧
            </div>
            <div style="flex: 1; justify-content: center;">
              操作
            </div>
          </div>
          <div style="height: 0.5rem;"></div>
          <!-- <div style="display: flex; text-align: center; margin: 1.2rem 0rem;">
            <div style="flex: 1;justify-content: center;">
              2021-01-05
            </div>
            <div style="flex: 1; justify-content: center;">
              99%
            </div>
            <div style="flex: 1; justify-content: center;">
              查看详情
            </div>
          </div> -->
          <div v-for="item in historyList" :key="item.id" style="display: flex; text-align: center; margin: 1.2rem 0rem;">
            <div style="flex: 1;justify-content: center;">
              {{ item.boDate }}
            </div>
            <div style="flex: 1; justify-content: center;">
              {{ item.boValue }}%
            </div>
            <div style="flex: 1; justify-content: center;">
              <!-- 查看详情 -->
            </div>
          </div>
        </div>
      </div>

      <!-- 血压 -->
      <div v-if="index==6">
        <div style="margin: 0.5rem 0rem; margin-top: 1rem; font-weight: 600;">血压</div>
        <div style="width: 100%; height: 3rem; background-color: rgb(255, 255, 255); border-radius: 0.5rem;">
          <div style="display: flex; text-align: center;">
            <div style="flex: 70;">
              <div style="margin: 0.5rem 0.5rem; font-weight: 600; font-size: 1.5rem;">{{ lastDataDetails.systolic }}/{{ lastDataDetails.diastolic }}mmHg</div>
            </div>
          </div>
        </div>
        <div style="height: 0.5rem;"></div>
        <div style="width: 100%; height: 8rem; background-color: rgb(255, 255, 255); border-radius: 0.5rem; ">
          <div style="position: relative;">
            <div style="position: absolute; top: 1rem; left: 1rem;">收缩压</div>
            <div v-if="lastDataDetails.systolicColorshow == 1" style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">低</div>
            <div v-if="lastDataDetails.systolicColorshow == 2" style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">正常</div>
            <div v-if="lastDataDetails.systolicColorshow == 3" style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">高</div>
          </div>
          <div style="position: relative;">
            <div class="tooltip" :style="{'margin-left': lastDataShow.systolicValueShow + '%'}">
              {{ lastDataDetails.systolic }}
            </div>
          </div>
          <div style="display: flex; text-align: center; position: relative; top: 4rem; margin: 0.5rem 0.5rem;">
            <div style="flex: 70; background-color: rgb(216, 216, 216); margin: 0rem 0.1rem; border-top-left-radius: 1rem; border-bottom-left-radius: 1rem;" :style="{'background-color': lastDataShow.systolicValueShowColor1}">
              <div style="color: rgb(255, 255, 255); margin: 0.2rem 0rem;">&lt;90</div>
            </div>
            <div style="flex: 70; background-color: rgb(57, 135, 247); margin: 0rem 0.1rem;" :style="{'background-color': lastDataShow.systolicValueShowColor2}">
              <div style="color: rgb(255, 255, 255); margin: 0.2rem 0rem;">90 - 140</div>
            </div>
            <div style="flex: 70; background-color: rgb(216, 216, 216); margin: 0rem 0.1rem; border-top-right-radius: 1rem; border-bottom-right-radius: 1rem;" :style="{'background-color': lastDataShow.systolicValueShowColor3}">
              <div style="color: rgb(255, 255, 255); margin: 0.2rem 0rem;">&gt;140</div>
            </div>
          </div>
          <div style="display: flex; text-align: center; position: relative; top: 4rem; margin: 0.5rem 0.5rem;">
            <div style="flex: 70;">
              <div>低</div>
            </div>
            <div style="flex: 70;">
              <div>正常</div>
            </div>
            <div style="flex: 70;">
              <div>高</div>
            </div>
          </div>
        </div>
        <div style="width: 100%; height: 8rem; background-color: rgb(255, 255, 255); border-radius: 0.5rem; ">
          <div style="position: relative;">
            <div style="position: absolute; top: 1rem; left: 1rem;">舒张压</div>
            <div v-if="lastDataDetails.diastolicColorshow == 1" style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">低</div>
            <div v-if="lastDataDetails.diastolicColorshow == 2" style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">正常</div>
            <div v-if="lastDataDetails.diastolicColorshow == 3" style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">高</div>
          </div>
          <div style="position: relative;">
            <div class="tooltip" :style="{'margin-left': lastDataShow.diastolicValueShow + '%'}">
              {{ lastDataDetails.diastolic }}
            </div>
          </div>
          <div style="display: flex; text-align: center; position: relative; top: 4rem; margin: 0.5rem 0.5rem;">
            <div style="flex: 70; background-color: rgb(216, 216, 216); margin: 0rem 0.1rem; border-top-left-radius: 1rem; border-bottom-left-radius: 1rem;" :style="{'background-color': lastDataShow.diastolicValueShowColor1}">
              <div style="color: rgb(255, 255, 255); margin: 0.2rem 0rem;">&lt;60</div>
            </div>
            <div style="flex: 70; background-color: rgb(57, 135, 247); margin: 0rem 0.1rem;" :style="{'background-color': lastDataShow.diastolicValueShowColor2}">
              <div style="color: rgb(255, 255, 255); margin: 0.2rem 0rem;">60 - 90</div>
            </div>
            <div style="flex: 70; background-color: rgb(216, 216, 216); margin: 0rem 0.1rem; border-top-right-radius: 1rem; border-bottom-right-radius: 1rem;" :style="{'background-color': lastDataShow.diastolicValueShowColor3}">
              <div style="color: rgb(255, 255, 255); margin: 0.2rem 0rem;">&gt;90</div>
            </div>
          </div>
          <div style="display: flex; text-align: center; position: relative; top: 4rem; margin: 0.5rem 0.5rem;">
            <div style="flex: 70;">
              <div>低</div>
            </div>
            <div style="flex: 70;">
              <div>正常</div>
            </div>
            <div style="flex: 70;">
              <div>高</div>
            </div>
          </div>
        </div>
        <div style="position: relative; margin-top: 1rem;">
          <div style="position: absolute; font-size: 1.1rem;">历史数据</div>
          <div style="position: absolute; margin-left: 5.5rem;">指标统计</div>
        </div>
        <div style="position: relative; margin-top: 3rem; width: 100%; background-color: rgb(255, 255, 255); border-radius: 0.5rem; padding: 1rem 1rem;">
          <div style="display: flex; text-align: center;">
            <div style="flex: 1;justify-content: center;">
              时间
            </div>
            <div style="flex: 1; justify-content: center;">
              舒张压/收缩压
            </div>
            <div style="flex: 1; justify-content: center;">
              操作
            </div>
          </div>
          <div style="height: 0.5rem;"></div>
          <!-- <div style="display: flex; text-align: center; margin: 1.2rem 0rem;">
            <div style="flex: 1;justify-content: center;">
              2021-01-05
            </div>
            <div style="flex: 1; justify-content: center;">
              113/63mmHg
            </div>
            <div style="flex: 1; justify-content: center;">
              查看详情
            </div>
          </div> -->
          <div v-for="item in historyList" :key="item.id" style="display: flex; text-align: center; margin: 1.2rem 0rem;">
            <div style="flex: 1;justify-content: center;">
              {{ item.meterDate }}
            </div>
            <div style="flex: 1; justify-content: center;">
              {{ item.systolic }}/{{ item.diastolic }}mmHg
            </div>
            <div style="flex: 1; justify-content: center;">
              <!-- 查看详情 -->
            </div>
          </div>
        </div>
      </div>

      <!-- 血糖 -->
      <div v-if="index==7">
        <div style="margin: 0.5rem 0rem; margin-top: 1rem; font-weight: 600;">血糖</div>
        <div style="width: 100%; height: 3rem; background-color: rgb(255, 255, 255); border-radius: 0.5rem;">
          <div style="display: flex; text-align: center;">
            <div style="flex: 70;">
              <div style="margin: 0.5rem 0.5rem; font-weight: 600; font-size: 1.5rem;">{{ lastDataDetails.bsValue }} mmol/L</div>
            </div>
          </div>
        </div>
        <div style="height: 0.5rem;"></div>
        <div style="width: 100%; height: 8rem; background-color: rgb(255, 255, 255); border-radius: 0.5rem; ">
          <div style="position: relative;">
            <div style="position: absolute; top: 1rem; left: 1rem;">血糖</div>
            <div v-if="lastDataDetails.bsValueColorshow == 1" style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">低</div>
            <div v-if="lastDataDetails.bsValueColorshow == 2" style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">正常</div>
            <div v-if="lastDataDetails.bsValueColorshow == 3" style="position: absolute; top: 1rem; right: 1rem; color: rgb(79, 154, 206);">高</div>
          </div>
          <div style="position: relative;">
            <div class="tooltip" :style="{'margin-left': lastDataShow.bsValueShow + '%'}">
              {{ lastDataDetails.bsValue }}
            </div>
          </div>
          <div style="display: flex; text-align: center; position: relative; top: 4rem; margin: 0.5rem 0.5rem;">
            <div style="flex: 70; background-color: rgb(216, 216, 216); margin: 0rem 0.1rem; border-top-left-radius: 1rem; border-bottom-left-radius: 1rem;" :style="{'background-color': lastDataShow.bsValueShowColor1}">
              <div style="color: rgb(255, 255, 255); margin: 0.2rem 0rem;">&lt;3.9</div>
            </div>
            <div style="flex: 70; background-color: rgb(57, 135, 247); margin: 0rem 0.1rem;" :style="{'background-color': lastDataShow.bsValueShowColor2}">
              <div style="color: rgb(255, 255, 255); margin: 0.2rem 0rem;">3.9 - 6.1</div>
            </div>
            <div style="flex: 70; background-color: rgb(216, 216, 216); margin: 0rem 0.1rem; border-top-right-radius: 1rem; border-bottom-right-radius: 1rem;" :style="{'background-color': lastDataShow.bsValueShowColor3}">
              <div style="color: rgb(255, 255, 255); margin: 0.2rem 0rem;">&gt;6.1</div>
            </div>
          </div>
          <div style="display: flex; text-align: center; position: relative; top: 4rem; margin: 0.5rem 0.5rem;">
            <div style="flex: 70;">
              <div>低</div>
            </div>
            <div style="flex: 70;">
              <div>正常</div>
            </div>
            <div style="flex: 70;">
              <div>高</div>
            </div>
          </div>
        </div>
        <div style="position: relative; margin-top: 1rem;">
          <div style="position: absolute; font-size: 1.1rem;">历史数据</div>
          <div style="position: absolute; margin-left: 5.5rem;">指标统计</div>
        </div>
        <div style="position: relative; margin-top: 3rem; width: 100%; background-color: rgb(255, 255, 255); border-radius: 0.5rem; padding: 1rem 1rem;">
          <div style="display: flex; text-align: center;">
            <div style="flex: 1;justify-content: center;">
              时间
            </div>
            <div style="flex: 1; justify-content: center;">
              血糖
            </div>
            <div style="flex: 1; justify-content: center;">
              操作
            </div>
          </div>
          <div style="height: 0.5rem;"></div>
          <!-- <div style="display: flex; text-align: center; margin: 1.2rem 0rem;">
            <div style="flex: 1;justify-content: center;">
              2021-01-05
            </div>
            <div style="flex: 1; justify-content: center;">
              6.00 mmol/L
            </div>
            <div style="flex: 1; justify-content: center;">
              查看详情
            </div>
          </div> -->
          <div v-for="item in historyList" :key="item.id" style="display: flex; text-align: center; margin: 1.2rem 0rem;">
            <div style="flex: 1;justify-content: center;">
              {{ item.date }}
            </div>
            <div style="flex: 1; justify-content: center;">
              {{ item.value }}mmol/L
            </div>
            <div style="flex: 1; justify-content: center;">
              <!-- 查看详情 -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </body>

</template>

<script>
import { getAvgByMonthPage } from '../../api/login'
export default {
  data () {
    return {
      requestHeadUrl: 'https://dbadmin.dbzdjk.com',
      imgurl: 'https://health-planet.oss-cn-shanghai.aliyuncs.com/report/2023-10-25/1a70eab03b75c84e9b7913b89ab291444704.jpg',
      count: 0,
      loading: false,
      scrolltimer: '',
      channel: window.localStorage.getItem('channel'),
      list:[],
      numberDetails: {},
      punchTheClock: {},
      dataAbnormalLog: '',
      sum: 0,
      lastDataShow: {
        weightShow: 9
      },
      historyList: {}
    }
  },
  created () {
    this.index = this.$route.query.index
    // this.wxUserId = this.$route.query.wxUserId
    // this.idCard = this.$route.query.idCard
    this.wxUserIdData = window.localStorage.getItem('wxUserIdData')
    this.idCard = window.localStorage.getItem('idCard')
    console.log('test wxUserIdData', this.wxUserIdData)
    console.log('test idCard', this.idCard)
    const lastDataDetails = JSON.parse(this.$route.query.lastDataDetails || '{}');
    this.lastDataDetails = lastDataDetails
    console.log('this.$route.query', this.$route.query)
    const thisObj = this
    thisObj.onLoad()
  },
  mounted() {
  },
  methods: {
    onLoad: function () {
      console.log('test index', this.index)
      console.log('test wxUserIdData', this.wxUserIdData)
      console.log('test idCard', this.idCard)
      console.log('test lastDataDetails', this.lastDataDetails)
      console.log(this.lastDataDetails)
      const date = new Date();
      const yearShow = date.getFullYear();
      const monthShow = (date.getMonth() + 1).toString().padStart(2, '0'); // 补零
      let month = yearShow + "-" + monthShow
      console.log('test month', month)
      if(this.index == 1){
        this.$set(this.lastDataShow, 'weightShow', this.lastDataDetails.weightColorshow==1?10:this.lastDataDetails.weightColorshow==2?40:70)
        this.$set(this.lastDataShow, 'weightShowColor1', this.lastDataDetails.weightColorshow==1?'rgb(57, 135, 247)':'rgb(216, 216, 216)')
        this.$set(this.lastDataShow, 'weightShowColor2', this.lastDataDetails.weightColorshow==2?'rgb(57, 135, 247)':'rgb(216, 216, 216)')
        this.$set(this.lastDataShow, 'weightShowColor3', this.lastDataDetails.weightColorshow==3?'rgb(57, 135, 247)':'rgb(216, 216, 216)')
      }else if(this.index == 2){
        this.$set(this.lastDataShow, 'btShow', this.lastDataDetails.btColorshow==1?10:this.lastDataDetails.btColorshow==2?40:70)
        this.$set(this.lastDataShow, 'btShowColor1', this.lastDataDetails.btColorshow==1?'rgb(57, 135, 247)':'rgb(216, 216, 216)')
        this.$set(this.lastDataShow, 'btShowColor2', this.lastDataDetails.btColorshow==2?'rgb(57, 135, 247)':'rgb(216, 216, 216)')
        this.$set(this.lastDataShow, 'btShowColor3', this.lastDataDetails.btColorshow==3?'rgb(57, 135, 247)':'rgb(216, 216, 216)')
      }else if(this.index == 3){
        this.$set(this.lastDataShow, 'heartRateShow', this.lastDataDetails.heartRateColorshow==1?10:this.lastDataDetails.heartRateColorshow==2?40:70)
        this.$set(this.lastDataShow, 'heartRateShowColor1', this.lastDataDetails.heartRateColorshow==1?'rgb(57, 135, 247)':'rgb(216, 216, 216)')
        this.$set(this.lastDataShow, 'heartRateShowColor2', this.lastDataDetails.heartRateColorshow==2?'rgb(57, 135, 247)':'rgb(216, 216, 216)')
        this.$set(this.lastDataShow, 'heartRateShowColor3', this.lastDataDetails.heartRateColorshow==3?'rgb(57, 135, 247)':'rgb(216, 216, 216)')
      }else if(this.index == 4){
        this.$set(this.lastDataShow, 'breathValueShow', this.lastDataDetails.breathValueColorshow==1?10:this.lastDataDetails.breathValueColorshow==2?40:70)
        this.$set(this.lastDataShow, 'breathValueShowColor1', this.lastDataDetails.breathValueColorshow==1?'rgb(57, 135, 247)':'rgb(216, 216, 216)')
        this.$set(this.lastDataShow, 'breathValueShowColor2', this.lastDataDetails.breathValueColorshow==2?'rgb(57, 135, 247)':'rgb(216, 216, 216)')
        this.$set(this.lastDataShow, 'breathValueShowColor3', this.lastDataDetails.breathValueColorshow==3?'rgb(57, 135, 247)':'rgb(216, 216, 216)')
      }else if(this.index == 5){
        this.$set(this.lastDataShow, 'boValueShow', this.lastDataDetails.boValueColorshow==1?10:this.lastDataDetails.boValueColorshow==2?40:70)
        this.$set(this.lastDataShow, 'boValueShowColor1', this.lastDataDetails.boValueColorshow==1?'rgb(57, 135, 247)':'rgb(216, 216, 216)')
        this.$set(this.lastDataShow, 'boValueShowColor2', this.lastDataDetails.boValueColorshow==2?'rgb(57, 135, 247)':'rgb(216, 216, 216)')
        this.$set(this.lastDataShow, 'boValueShowColor3', this.lastDataDetails.boValueColorshow==3?'rgb(57, 135, 247)':'rgb(216, 216, 216)')
      }else if(this.index == 6){
        // 收缩压 systolic
        this.$set(this.lastDataShow, 'systolicValueShow', this.lastDataDetails.systolicColorshow==1?10:this.lastDataDetails.systolicColorshow==2?40:70)
        this.$set(this.lastDataShow, 'systolicValueShowColor1', this.lastDataDetails.systolicColorshow==1?'rgb(57, 135, 247)':'rgb(216, 216, 216)')
        this.$set(this.lastDataShow, 'systolicValueShowColor2', this.lastDataDetails.systolicColorshow==2?'rgb(57, 135, 247)':'rgb(216, 216, 216)')
        this.$set(this.lastDataShow, 'systolicValueShowColor3', this.lastDataDetails.systolicColorshow==3?'rgb(57, 135, 247)':'rgb(216, 216, 216)')
        // 舒张压 diastolic
        this.$set(this.lastDataShow, 'diastolicValueShow', this.lastDataDetails.diastolicColorshow==1?10:this.lastDataDetails.diastolicColorshow==2?40:70)
        this.$set(this.lastDataShow, 'diastolicValueShowColor1', this.lastDataDetails.diastolicColorshow==1?'rgb(57, 135, 247)':'rgb(216, 216, 216)')
        this.$set(this.lastDataShow, 'diastolicValueShowColor2', this.lastDataDetails.diastolicColorshow==2?'rgb(57, 135, 247)':'rgb(216, 216, 216)')
        this.$set(this.lastDataShow, 'diastolicValueShowColor3', this.lastDataDetails.diastolicColorshow==3?'rgb(57, 135, 247)':'rgb(216, 216, 216)')
      }else if(this.index == 7){
        this.$set(this.lastDataShow, 'bsValueShow', this.lastDataDetails.bsValueColorshow==1?10:this.lastDataDetails.bsValueColorshow==2?40:70)
        this.$set(this.lastDataShow, 'bsValueShowColor1', this.lastDataDetails.bsValueColorshow==1?'rgb(57, 135, 247)':'rgb(216, 216, 216)')
        this.$set(this.lastDataShow, 'bsValueShowColor2', this.lastDataDetails.bsValueColorshow==2?'rgb(57, 135, 247)':'rgb(216, 216, 216)')
        this.$set(this.lastDataShow, 'bsValueShowColor3', this.lastDataDetails.bsValueColorshow==3?'rgb(57, 135, 247)':'rgb(216, 216, 216)')
      }
      getAvgByMonthPage(this.idCard,this.wxUserIdData,month,this.index).then(res => {
        const data = res.data
        console.log('test getAvgByMonthPage')
        console.log(data)
        this.historyList = data
      })
    },
    toDetailPage (data) {
      this.$router.push({
        path: '/health/bpmeterdata_value',
        query: { wxUserIdData: data.wxUserIdData }
      })
    },

    // weightDataShow() {
    //       return {
    //         'margin-left': `${this.lastDataShow.weightShow}rem`
    //       }
    //     }
  }
}
</script>

<style>
  body {
    background-color: rgb(245, 245, 245);
    background-size: cover;
    background-position: center;
    line-height: 1.15;
  }
  .bodytest{
    overflow-y: auto;
  }
.tooltip{
  width: 5rem;
  background-color: rgba(0,0,0,.6);
  color: #fff;
  text-align: center;
  font-size: 1rem;
  border-radius: 8px;
  padding: 0.1rem 0.1rem;
  position: absolute;
  z-index: 1;
  top: 2.4rem;
  transition: all .3s linear;
  margin-left: 9rem;
}
</style>
